import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Typography from '@material-ui/core/Typography';
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { userLogoutAction } from "../../store/user/asyncActions";

const Home = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    document.title = 'Dashboard - Natural Veneers';
  }, []);

  return (
    <div className="px-1 pt-1">
      <Typography variant='h6' color="textSecondary" className="mb-2">
        DASHBOARD
      </Typography>
      <Button variant="contained" color="primary" className="mb-1" fullWidth
        onClick={() => {
          history.push('/new-order');
        }}
      >
        ADD NEW ORDER
      </Button>
      <Button variant="contained" color="default" fullWidth
        onClick={() => {
          history.push('/orders');
        }}
      >
        VIEW ALL ORDERS
      </Button>
    </div>
  );
};

export default Home;
