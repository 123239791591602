import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { isValidEmail } from "../../helpers";
import Joi from "joi";

import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { userLoginAction } from "../../store/user/asyncActions";
import { useSelector } from "react-redux";
import InstallPopup from "../../components/InstallPopup/InstallPopup";

const Login = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const userData = localStorage.getItem('user');
  if (userData && userData.trim() !== '') {
    history.push('/');
    return (<></>);
  }

  const loginData = useSelector((state) => state?.user?.login);

  const [loading, setLoading] = useState(false);
  const [loginFormData, setLoginFormData] = useState({
    mobile: ''
  });

  useEffect(() => {
    document.title = 'Login - Natural Veneers';
  }, []);

  useEffect(() => {
    if (loginData?.data?.success) {
      localStorage.setItem('login_message', loginData?.data?.message);
      localStorage.setItem('mobile', loginFormData?.mobile);

      loginData.data = null;
      localStorage.removeItem('user');

      history.push('/otp-verification');
    } else if (loginData?.data?.error) {
      setLoading(false);
    }
  }, [loginData]);

  const loginDataHandleChange = (e) => {
    setLoginFormData({
      ...loginFormData,
      [e.target.name]: e.target.value
    });
  }

  const handleMobileChange = (e) => {
    const val = e.target.value;
    if (val.length <= 10 && !isNaN(val)) {
      loginDataHandleChange(e);
    } else {
      e.preventDefault();
    }
  };

  const [errors, setErrors] = useState({});
  const schema = Joi.object({
    mobile: Joi.string()
      .regex(/^[0-9]{10}$/) // Assuming a 10-digit mobile number
      .required()
      .label('Mobile Number')
      .messages({
        'string.pattern.base': 'Mobile number must be 10 digits',
        'any.required': 'Mobile number is required',
      }),
  });

  const do_login = (e) => {
    e.preventDefault();

    const validationResult = schema.validate(loginFormData, { abortEarly: false });

    if (validationResult.error) {
      const validationErrors = {};
      validationResult.error.details.forEach((err) => {
        validationErrors[err.path[0]] = err.message;
      });
      setErrors(validationErrors);
    } else {
      setErrors({});
      setLoading(true);
      dispatch(userLoginAction(loginFormData));
    }
  }

  return (
    <div className="login_main">
      <form onSubmit={(e) => { do_login(e) }}>
        <div className="logo_div px-2">
          <img
            src={`${process.env.PUBLIC_URL}/static/images/main-logo.png`}
            alt=""
            className="login_logo"
          />
          <Typography variant="h5" className="pt-3 pb-1">
            Login
          </Typography>
        </div>
        <div className="px-2">
          <TextField
            className="login_form_field"
            name="mobile"
            label="Mobile Number"
            variant="outlined"
            size="small"
            placeholder="Enter your mobile number"
            value={loginFormData?.mobile}
            error={errors?.mobile}
            helperText={errors?.mobile}
            onChange={(e) => handleMobileChange(e)}
          />
          <Button
            className="login_form_btn"
            variant="contained"
            color="primary"
            type="submit"
            disabled={loading}
          >
            <ArrowForwardIosIcon />
          </Button>
        </div>
      </form>
      <InstallPopup />
    </div>
  );
};

export default Login;
