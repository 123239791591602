import { CHANGE_ORDER_STATUS_ERROR, CHANGE_ORDER_STATUS_LOADING, CHANGE_ORDER_STATUS_SUCCESS, CREATE_ORDER_ERROR, CREATE_ORDER_LOADING, CREATE_ORDER_SUCCESS, DELETE_ORDER_ERROR, DELETE_ORDER_LOADING, DELETE_ORDER_SUCCESS, GET_CATEGORIES_ERROR, GET_CATEGORIES_LOADING, GET_CATEGORIES_SUCCESS, GET_CUSTOMER_ERROR, GET_CUSTOMER_LOADING, GET_CUSTOMER_SUCCESS, GET_ITEMS_ERROR, GET_ITEMS_LOADING, GET_ITEMS_SUCCESS, GET_ORDERS_ERROR, GET_ORDERS_LOADING, GET_ORDERS_SUCCESS, GET_ORDER_DETAILS_ERROR, GET_ORDER_DETAILS_LOADING, GET_ORDER_DETAILS_SUCCESS, GET_ORDER_FORM_ERROR, GET_ORDER_FORM_LOADING, GET_ORDER_FORM_SUCCESS, GET_SALES_PERSON_ERROR, GET_SALES_PERSON_LOADING, GET_SALES_PERSON_SUCCESS, PRINT_ORDER_ERROR, PRINT_ORDER_LOADING, PRINT_ORDER_SUCCESS, UPDATE_ORDER_ERROR, UPDATE_ORDER_LOADING, UPDATE_ORDER_SUCCESS } from "./actionTypes";

const initialState = {
    salesPersons: {
        loading: false,
        data: null,
        error: null
    },
    customers: {
        loading: false,
        data: null,
        error: null
    },
    categories: {
        loading: false,
        data: null,
        error: null
    },
    items: {
        loading: false,
        data: null,
        error: null
    },
    orderForm: {
        loading: false,
        data: null,
        error: null
    },
    createOrder: {
        loading: false,
        data: null,
        error: null
    },
    getOrders: {
        loading: false,
        data: null,
        error: null
    },
    orderDetails: {
        loading: false,
        data: null,
        error: null
    },
    updateOrder: {
        loading: false,
        data: null,
        error: null
    },
    deleteOrder: {
        loading: false,
        data: null,
        error: null
    },
    changeStatus: {
        loading: false,
        data: null,
        error: null
    },
    printOrder: {
        loading: false,
        data: null,
        error: null
    },
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_SALES_PERSON_LOADING:
            return { ...state, salesPersons: { ...state.salesPersons, loading: true, data: null, error: null } };
        case GET_SALES_PERSON_SUCCESS:
            return { ...state, salesPersons: { ...state.salesPersons, loading: false, data: action.payload, error: null } };
        case GET_SALES_PERSON_ERROR:
            return { ...state, salesPersons: { ...state.salesPersons, loading: false, data: null, error: action.payload } };

        case GET_CUSTOMER_LOADING:
            return { ...state, customers: { ...state.customers, loading: true, data: null, error: null } };
        case GET_CUSTOMER_SUCCESS:
            return { ...state, customers: { ...state.customers, loading: false, data: action.payload, error: null } };
        case GET_CUSTOMER_ERROR:
            return { ...state, customers: { ...state.customers, loading: false, data: null, error: action.payload } };

        case GET_CATEGORIES_LOADING:
            return { ...state, categories: { ...state.categories, loading: true, data: null, error: null } };
        case GET_CATEGORIES_SUCCESS:
            return { ...state, categories: { ...state.categories, loading: false, data: action.payload, error: null } };
        case GET_CATEGORIES_ERROR:
            return { ...state, categories: { ...state.categories, loading: false, data: null, error: action.payload } };

        case GET_ITEMS_LOADING:
            return { ...state, items: { ...state.items, loading: true, data: null, error: null } };
        case GET_ITEMS_SUCCESS:
            return { ...state, items: { ...state.items, loading: false, data: action.payload, error: null } };
        case GET_ITEMS_ERROR:
            return { ...state, items: { ...state.items, loading: false, data: null, error: action.payload } };

        case GET_ORDER_FORM_LOADING:
            return { ...state, orderForm: { ...state.orderForm, loading: true, data: null, error: null } };
        case GET_ORDER_FORM_SUCCESS:
            return { ...state, orderForm: { ...state.orderForm, loading: false, data: action.payload, error: null } };
        case GET_ORDER_FORM_ERROR:
            return { ...state, orderForm: { ...state.orderForm, loading: false, data: null, error: action.payload } };

        case CREATE_ORDER_LOADING:
            return { ...state, createOrder: { ...state.createOrder, loading: true, data: null, error: null } };
        case CREATE_ORDER_SUCCESS:
            return { ...state, createOrder: { ...state.createOrder, loading: false, data: action.payload, error: null } };
        case CREATE_ORDER_ERROR:
            return { ...state, createOrder: { ...state.createOrder, loading: false, data: null, error: action.payload } };

        case GET_ORDERS_LOADING:
            return { ...state, getOrders: { ...state.getOrders, loading: true, data: null, error: null } };
        case GET_ORDERS_SUCCESS:
            return { ...state, getOrders: { ...state.getOrders, loading: false, data: action.payload, error: null } };
        case GET_ORDERS_ERROR:
            return { ...state, getOrders: { ...state.getOrders, loading: false, data: null, error: action.payload } };

        case GET_ORDER_DETAILS_LOADING:
            return { ...state, orderDetails: { ...state.orderDetails, loading: true, data: null, error: null } };
        case GET_ORDER_DETAILS_SUCCESS:
            return { ...state, orderDetails: { ...state.orderDetails, loading: false, data: action.payload, error: null } };
        case GET_ORDER_DETAILS_ERROR:
            return { ...state, orderDetails: { ...state.orderDetails, loading: false, data: null, error: action.payload } };

        case UPDATE_ORDER_LOADING:
            return { ...state, updateOrder: { ...state.updateOrder, loading: true, data: null, error: null } };
        case UPDATE_ORDER_SUCCESS:
            return { ...state, updateOrder: { ...state.updateOrder, loading: false, data: action.payload, error: null } };
        case UPDATE_ORDER_ERROR:
            return { ...state, updateOrder: { ...state.updateOrder, loading: false, data: null, error: action.payload } };

        case DELETE_ORDER_LOADING:
            return { ...state, deleteOrder: { ...state.deleteOrder, loading: true, data: null, error: null } };
        case DELETE_ORDER_SUCCESS:
            return { ...state, deleteOrder: { ...state.deleteOrder, loading: false, data: action.payload, error: null } };
        case DELETE_ORDER_ERROR:
            return { ...state, deleteOrder: { ...state.deleteOrder, loading: false, data: null, error: action.payload } };

        case CHANGE_ORDER_STATUS_LOADING:
            return { ...state, changeStatus: { ...state.changeStatus, loading: true, data: null, error: null } };
        case CHANGE_ORDER_STATUS_SUCCESS:
            return { ...state, changeStatus: { ...state.changeStatus, loading: false, data: action.payload, error: null } };
        case CHANGE_ORDER_STATUS_ERROR:
            return { ...state, changeStatus: { ...state.changeStatus, loading: false, data: null, error: action.payload } };

        case PRINT_ORDER_LOADING:
            return { ...state, printOrder: { ...state.printOrder, loading: true, data: null, error: null } };
        case PRINT_ORDER_SUCCESS:
            return { ...state, printOrder: { ...state.printOrder, loading: false, data: action.payload, error: null } };
        case PRINT_ORDER_ERROR:
            return { ...state, printOrder: { ...state.printOrder, loading: false, data: null, error: action.payload } };

        default:
            return state;
    }
};

export default reducer;
