// InstallPopup.js

import { Button, IconButton, Snackbar } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import CloseIcon from '@material-ui/icons/Close';

const InstallPopup = () => {
  const [showPrompt, setShowPrompt] = useState(false);

  useEffect(() => {
    const handleBeforeInstallPrompt = (e) => {
      // Prevent Chrome 67 and earlier from automatically showing the prompt
      e.preventDefault();
      // Stash the event so it can be triggered later.
      setShowPrompt(e);
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, []);

  const handleInstall = () => {
    if (showPrompt) {
      // Show the install prompt
      showPrompt.prompt();
      // Wait for the user to respond to the prompt
      showPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the install prompt');
        } else {
          console.log('User dismissed the install prompt');
        }
      });
    }
  };

  const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const action = (
    <React.Fragment>
      <Button variant='contained' color="primary" size="small" style={{marginRight: '10px'}} onClick={handleInstall}>
        Install
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div>
      {showPrompt && open && (
        <Snackbar
          open={open}
          autoHideDuration={180000}
          onClose={handleClose}
          message="Install this app?"
          action={action}
        />
      )}
    </div>
  );
};

export default InstallPopup;
