// rootReducer.js
import { combineReducers } from 'redux';
import userReducer from './user/reducer';
import newOrderReducer from './new-order/reducer';


const rootReducer = combineReducers({
  user: userReducer,
  newOrder: newOrderReducer,
});

export default rootReducer;
