import { resendOtpError, resendOtpLoading, resendOtpSuccess, userLoginError, userLoginLoading, userLoginSuccess, verifyOtpError, verifyOtpLoading, verifyOtpSuccess } from './actions';
import api from '../api';
import { toast } from 'react-toastify';

const userLoginAction = (postData) => {
  return async (dispatch) => {
    try {
      // Start the loading state
      dispatch(userLoginLoading());

      // Perform the asynchronous operation (e.g., API call)
      const response = await api.post('services/ord_services_v1.php', { ...postData, action: 'userLogin' });
      const data = response.data;

      // Dispatch success action with the received data
      dispatch(userLoginSuccess(data));
      if (data?.success) {
        // toast.success(data?.message);
      }
      if (data?.error) {
        toast.error(data?.error);
      }
    } catch (error) {
      // Dispatch error action if an error occurs
      dispatch(userLoginError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

const verifyOtpAction = (postData) => {
  return async (dispatch) => {
    try {
      // Start the loading state
      dispatch(verifyOtpLoading());

      // Perform the asynchronous operation (e.g., API call)
      const response = await api.post('services/ord_services_v1.php', { ...postData, action: 'verifyOTP' });
      const data = response.data;

      // Dispatch success action with the received data
      dispatch(verifyOtpSuccess(data));
      if (data?.success) {
        // toast.success(data?.message);
      }
      if (data?.error) {
        toast.error(data?.error);
      }
    } catch (error) {
      // Dispatch error action if an error occurs
      dispatch(verifyOtpError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

const resendOtpAction = (postData) => {
  return async (dispatch) => {
    try {
      // Start the loading state
      dispatch(resendOtpLoading());

      // Perform the asynchronous operation (e.g., API call)
      const response = await api.post('services/ord_services_v1.php', { ...postData, action: 'resendOTP' });
      const data = response.data;

      // Dispatch success action with the received data
      dispatch(resendOtpSuccess(data));
      if (data?.success) {
        toast.success(data?.message);
      }
      if (data?.error) {
        toast.error(data?.error);
      }
    } catch (error) {
      // Dispatch error action if an error occurs
      dispatch(resendOtpError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

const userLogoutAction = () => {
  try {
    localStorage.removeItem('user');
    return true;
  } catch (error) {
    return false;
  }
};

export {
  userLoginAction,
  verifyOtpAction,
  resendOtpAction,
  userLogoutAction
};
