import { AppBar, Button, Checkbox, Dialog, DialogActions, DialogContent, FormControlLabel, FormHelperText, IconButton, InputAdornment, OutlinedInput, Slide, TextField, Toolbar, Typography, makeStyles } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { isEqual } from 'lodash';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles({
    smallerOutlinedInput: {
        '& .MuiOutlinedInput-input': {
            padding: '10px',
            paddingLeft: '12px',
            paddingRight: '12px',
            fontSize: '0.875rem', // Adjust as needed
        },
        '& .MuiInputLabel-outlined': {
            fontSize: '0.875rem', // Adjust as needed
        },
    },
});

const CustomSelect = ({ className, title, onSearch, onSelect, options, labelKey, multiple, value, error, helperText, disabled }) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [opt, setOpt] = useState([]);
    const [checkedItem, setCheckedItem] = useState(null);
    const [checkedItems, setCheckedItems] = useState([]);

    useEffect(() => {
        setOpt(options);
    }, [options]);

    useEffect(() => {
        if (value) {
            if (multiple) {
                setCheckedItems(Array.isArray(value) ? value : []);
            } else {
                setCheckedItem(value);
            }
        }
    }, [value]);

    const handleCheckboxChange = (item) => {
        if (isEqual(checkedItem, item)) {
            // If the clicked item is already checked, uncheck it
            setCheckedItem(null);
            onSelect(null); // Clear the selection
        } else {
            setCheckedItem(item);
            onSelect(item);
        }
    };

    const handleCheckboxChangeMulti = (item) => {
        const currentIndex = checkedItems.indexOf(item);
        const newChecked = [...checkedItems];

        if (currentIndex === -1) {
            // Add the item if it's not already checked
            newChecked.push(item);
        } else {
            // Remove the item if it's already checked
            newChecked.splice(currentIndex, 1);
        }

        setCheckedItems(newChecked);
        onSelect(newChecked); // Pass the updated checked items to the onSelect function
    };

    const filter = (searchTerm) => {
        if (searchTerm && searchTerm.trim() !== '') {
            const regex = new RegExp(searchTerm, 'i');
            const filteredData = options.filter(item => {
                return regex.test(item?.[labelKey]);
            });
            setOpt(filteredData);
        } else {
            setOpt(options);
        }
    }

    const modalClose = () => {
        setOpen(false);
        setOpt(options);
    }

    return (
        <div className={className}>
            <OutlinedInput
                style={{ pointerEvents: disabled ? 'none' : 'all' }}
                placeholder={title}
                fullWidth
                readOnly
                disabled={disabled}
                className={classes.smallerOutlinedInput}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton edge="end" disabled={disabled}>
                            <ExpandMoreIcon />
                        </IconButton>
                    </InputAdornment>
                }
                value={multiple ? value.map(x => x?.[labelKey]).join(',  ') : value?.[labelKey] || ''}
                onClick={() => setOpen(true)}
                error={error}
            />
            {error && <FormHelperText error>{helperText}</FormHelperText>}

            <Dialog
                fullScreen
                open={!disabled && open}
                onClose={modalClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            {title || ''}
                        </Typography>
                        <IconButton
                            className='ml_auto px-0'
                            edge="start"
                            color="inherit"
                            onClick={modalClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <DialogContent className='CuSel_DialogContent'>
                    <div>
                        <TextField
                            name="search_qry"
                            variant="outlined"
                            size="small"
                            placeholder="Search..."
                            fullWidth
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon style={{ color: '#737373' }} />
                                    </InputAdornment>
                                ),
                            }}
                            onChange={(e) => {
                                if (onSearch) {
                                    onSearch(e?.target?.value);
                                }
                                filter(e?.target?.value);
                            }}
                        />
                    </div>

                    <div className='CuSel_OptList'>
                        {opt && opt.map((item, index) => {
                            return (
                                <div key={index}>
                                    <FormControlLabel
                                        control={<Checkbox color='primary'
                                            checked={multiple ? checkedItems.some(checkedItem => isEqual(checkedItem, item)) : isEqual(checkedItem, item)}
                                            onChange={() => {
                                                if (multiple) {
                                                    handleCheckboxChangeMulti(item);
                                                } else {
                                                    handleCheckboxChange(item);
                                                }
                                            }}
                                        />}
                                        label={item?.[labelKey]}
                                    />
                                </div>
                            )
                        })}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus fullWidth variant='contained' color="primary" onClick={modalClose}>
                        Done
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default CustomSelect;
