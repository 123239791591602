import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Typography from '@material-ui/core/Typography';
import { Button, Card, TextField } from "@material-ui/core";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { Autocomplete } from "@material-ui/lab";
import CloseIcon from '@material-ui/icons/Close';
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom";
import { createOrderAction, getCategoriesAction, getCustomerAction, getItemsAction, getOrderDetailsAction, getOrderFormAction, getSalesPersonAction, updateOrderAction } from "../../store/new-order/asyncActions";
import { useSelector } from "react-redux";
import Joi from "joi";
import CustomSelect from "../../components/CustomSelect/CustomSelect";

const OrderDetails = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const userData = localStorage.getItem('user');
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id');

  useEffect(() => {
    document.title = 'New Order - Natural Veneers';
  }, []);

  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [redir, setRedir] = useState(null);

  useEffect(() => {
    if (userData && userData.trim() !== '') {
      setUser(JSON.parse(userData));
    }
  }, [userData]);

  useEffect(() => {
    if (user && id && id.trim() !== '') {
      dispatch(getOrderDetailsAction({
        auth_token: user?.auth_token,
        user_type: user?.user_type,
        user_id: user?.user_id,
        order_id: id
      }))
    }
  }, [user, id]);

  useEffect(() => {
    if (user) {
      setOrderForm({
        ...orderForm,
        auth_token: user?.auth_token,
        user_type: user?.user_type,
        user_id: user?.user_id,
      });
      dispatch(getSalesPersonAction({
        auth_token: user?.auth_token,
        user_type: user?.user_type,
        user_id: user?.user_id,
      }));
      dispatch(getCategoriesAction({
        auth_token: user?.auth_token,
        user_type: user?.user_type,
        user_id: user?.user_id,
      }));
    }
  }, [user]);

  const salesPersonsData = useSelector((state) => state?.newOrder?.salesPersons);
  const customersData = useSelector((state) => state?.newOrder?.customers);
  const categoryData = useSelector((state) => state?.newOrder?.categories);
  const itemsData = useSelector((state) => state?.newOrder?.items);
  const formFieldData = useSelector((state) => state?.newOrder?.orderForm);
  const createOrderData = useSelector((state) => state?.newOrder?.createOrder);
  const orderDetailsData = useSelector((state) => state?.newOrder?.orderDetails);
  const updateOrderData = useSelector((state) => state?.newOrder?.updateOrder);

  const [orderItems, setOrderItems] = useState([{
    category: undefined,
    item_id: undefined,
    qty: undefined,
    rate: undefined,
    comments: undefined
  }]);

  const [orderForm, setOrderForm] = useState(null);

  const [salesPerson, setSalesPerson] = useState(null);
  const [selecedSalesPerson, setSelecedSalesPerson] = useState(null);

  const [customers, setCustomers] = useState(null);
  const [selecedCustomers, setSelecedCustomers] = useState(null);

  const [categories, setCategories] = useState(null);
  const [selecedCategory, setSelecedCategory] = useState([]);

  const [items, setItems] = useState([]);
  const [selecedItem, setSelecedItem] = useState([]);

  const [itemsIndex, setItemsIndex] = useState(null);
  const [formFields, setFormFields] = useState([]);

  const [orderDetails, setOrderDetails] = useState(null);

  useEffect(() => {
    if (orderItems) {
      setOrderForm({
        ...orderForm,
        items: orderItems
      });
    }
  }, [orderItems]);

  useEffect(() => {
    if (salesPersonsData?.data?.success) {
      setSalesPerson(salesPersonsData?.data?.data);
    }
  }, [salesPersonsData]);

  useEffect(() => {
    if (customersData?.data?.success) {
      setCustomers(customersData?.data?.data);
    }
  }, [customersData]);

  useEffect(() => {
    if (categoryData?.data?.success) {
      setCategories(categoryData?.data?.data);
    }
  }, [categoryData]);

  useEffect(() => {
    if (itemsData?.data?.success) {
      addItemAtIndex(itemsIndex, itemsData?.data?.data);
    } else if (itemsData?.data?.error) {
      addItemAtIndex(itemsIndex, null);
    }
  }, [itemsData]);

  useEffect(() => {
    if (formFieldData?.data?.success) {
      addFormFieldAtIndex(itemsIndex, formFieldData?.data?.data);
    } else if (formFieldData?.data?.error) {
      addFormFieldAtIndex(itemsIndex, null);
    }
  }, [formFieldData]);

  useEffect(() => {
    if (createOrderData?.data?.success) {
      setLoading(false);
      createOrderData.data = null;
      if (redir && redir === 'New') {
        window.location.reload();
      } else if (redir && redir === 'Exit') {
        history.push('/orders');
      }
    } else if (createOrderData?.data?.error) {
      setLoading(false);
      createOrderData.data = null;
    }
  }, [createOrderData]);

  useEffect(() => {
    if (orderDetailsData?.data?.success) {
      setOrderDetails(orderDetailsData?.data?.data);
      orderDetailsData.data = null;
    }
  }, [orderDetailsData]);

  useEffect(() => {
    if (updateOrderData?.data?.success) {
      setLoading(false);
      updateOrderData.data = null;
      if (redir && redir === 'New') {
        window.location.href = '/new-order';
      } else if (redir && redir === 'Exit') {
        history.push('/orders');
      }
    } else if (updateOrderData?.data?.error) {
      setLoading(false);
      updateOrderData.data = null;
    }
  }, [updateOrderData]);

  useEffect(() => {
    if (orderDetails) {
      setOrderItems(orderDetails?.items.map((itm) => {
        return {
          ...itm,
          item_id: itm?.item?.item_id
        }
      }));
      setOrderForm({
        ...orderForm,
        auth_token: user?.auth_token,
        user_type: user?.user_type,
        user_id: user?.user_id,
        order_id: id,
        sales_person_id: orderDetails?.sales_person_id,
        customer_id: orderDetails?.customer_id,
        items: orderItems,
      });
      if (orderDetails?.items && categories) {
        const eidtCategories = [];
        const eidtItems = [];
        const eidtFormField = [];
        orderDetails?.items.map((item) => {
          eidtCategories.push(categories.filter(x => x.id === item?.category)?.[0]);
          eidtItems.push({ id: item?.item?.item_id, name: item?.item?.item_name });
          eidtFormField.push(item?.data);
        });
        setSelecedCategory(eidtCategories);
        setSelecedItem(eidtItems);
        setFormFields(eidtFormField);
      }
    }

    if (orderDetails && salesPerson) {
      setSelecedSalesPerson(salesPerson.filter(x => x?.id === orderDetails?.sales_person_id)?.[0]);
    }
    if (orderDetails?.customer_id && orderDetails?.customer_name) {
      setSelecedCustomers({ id: orderDetails?.customer_id, name: orderDetails?.customer_name })
    }

  }, [orderDetails, salesPerson, categories]);



  const addItemAtIndex = (index, data) => {
    const newArray = [...items];
    newArray[index] = data;
    setItems(newArray);
  };



  const addFormFieldAtIndex = (index, data) => {
    const newArray = [...formFields];
    newArray[index] = data;
    setFormFields(newArray);
  };


  const findOptionObject = (options, id) => {
    const result = options.filter(x => id === x.id);
    return result[0];
  }

  const findOptionMultipleObject = (options, arr) => {
    if (!options || !arr) {
      return [];
    }
    const result = options.filter(item => arr.includes(item.id));
    return result;
  }

  const [errors, setErrors] = useState({});

  return (
    <>
      <div className="px-1 pt-1">
        <div className="title_With_Back mb-1">
          <Typography variant='h6' color="textSecondary">
            ORDER DETAILS
          </Typography>
          <Button variant="text" color="primary" onClick={() => {
            history.goBack();
          }}>
            <ArrowBackIosIcon />Back
          </Button>
        </div>
        <div>
          <CustomSelect
            className="mb-1"
            title={'Select Sales Person'}
            options={salesPerson || []}
            labelKey={'name'}
            value={selecedSalesPerson}
            error={errors?.sales_person_id}
            helperText={errors?.sales_person_id}
            disabled
            onSelect={(selectedOptions) => {
            }}
          />
          <CustomSelect
            className="mb-1"
            title={'Select Party Name'}
            options={customers || []}
            labelKey={'name'}
            value={selecedCustomers}
            error={errors?.customer_id}
            helperText={errors?.customer_id}
            disabled
            onSelect={(selectedOptions) => {
            }}
          />
        </div>
        <div className="mt-2">
          <Typography variant='h6' color="textSecondary" className="oreder_summary_title">
            ORDER SUMMARY
          </Typography>
        </div>
        <div>

          {orderItems && orderItems.map((x, index) => {
            return (
              <Card className="py-1 px-1 my-1" key={index}>
                <div className="order_item_title">
                  <Typography variant='subtitle2' color="inherit">
                    Item 0{index + 1}
                  </Typography>
                </div>
                <div className="mt-1">
                  <CustomSelect
                    className="mb-1"
                    title={'Select Category'}
                    options={categories || []}
                    labelKey={'name'}
                    value={selecedCategory?.[index] || null}
                    error={errors?.items?.[index]?.category}
                    helperText={errors?.items?.[index]?.category}
                    disabled
                    onSelect={(selectedOptions) => {
                    }}
                  />
                  <CustomSelect
                    className="mb-1"
                    title={'Select Item'}
                    options={items?.[index] || []}
                    labelKey={'name'}
                    value={selecedItem?.[index] || null}
                    error={errors?.items?.[index]?.item_id}
                    helperText={errors?.items?.[index]?.item_id}
                    disabled
                    onSelect={(selectedOptions) => {
                    }}
                  />

                  {formFields && formFields?.[index] && formFields?.[index].map((itm, i) => {
                    if (itm?.type === 'dropdown') {
                      return (
                        <CustomSelect
                          key={i}
                          className="mb-1"
                          title={itm?.name}
                          options={itm?.options || []}
                          labelKey={'name'}
                          value={findOptionObject(itm?.options, orderItems?.[index]?.[itm?.id]) || null}
                          disabled
                          onSelect={(selectedOptions) => {
                          }}
                        />
                      )
                    }
                    if (itm?.type === 'multidropdown') {
                      return (
                        <CustomSelect
                          key={i}
                          className="mb-1"
                          multiple={true}
                          title={itm?.name}
                          options={itm?.options || []}
                          labelKey={'name'}
                          value={findOptionMultipleObject(itm?.options, orderItems?.[index]?.[itm?.id]) || []}
                          disabled
                          onSelect={(selectedOptions) => {
                          }}
                        />
                      )
                    }
                    if (itm?.type === 'input') {
                      return (
                        <TextField
                          key={i}
                          className="login_form_field mb-1"
                          name={itm?.id}
                          variant="outlined"
                          size="small"
                          placeholder={itm?.name}
                          fullWidth
                          value={orderItems?.[index]?.[itm?.id] || ''}
                          disabled
                          onChange={(e) => {
                          }}
                        />
                      )
                    }
                    if (itm?.type === 'file') {
                      if (orderItems?.[index]?.[itm?.id] && orderItems?.[index]?.[itm?.id].trim() !== '') {
                        return (
                          <div className='fileDragArea mb-1' key={i}>
                            <div className='imgPrevOuter'>
                              <img src={orderItems?.[index]?.[itm?.id]} alt="Image Preview" />
                            </div>
                          </div>
                        )
                      }
                    }
                  })}
                  <TextField
                    className="login_form_field mb-1"
                    name="qty"
                    variant="outlined"
                    size="small"
                    placeholder="Qty"
                    fullWidth
                    error={errors?.items?.[index]?.qty}
                    helperText={errors?.items?.[index]?.qty}
                    value={orderItems?.[index]?.['qty'] || ''}
                    disabled
                    onChange={(e) => {
                    }}
                  />
                  <TextField
                    className="login_form_field mb-1"
                    name="rate"
                    variant="outlined"
                    size="small"
                    placeholder="Rate"
                    fullWidth
                    error={errors?.items?.[index]?.rate}
                    helperText={errors?.items?.[index]?.rate}
                    value={orderItems?.[index]?.['rate'] || ''}
                    disabled
                    onChange={(e) => {
                    }}
                  />
                  <TextField
                    className="login_form_field"
                    name="comments"
                    variant="outlined"
                    multiline
                    rows={4}
                    placeholder="Comments..."
                    fullWidth
                    value={orderItems?.[index]?.['comments'] || ''}
                    disabled
                    onChange={(e) => {
                    }}
                  />
                </div>
              </Card>
            )
          })}

        </div>
      </div>

    </>
  );
};

export default OrderDetails;
