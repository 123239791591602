import { RESEND_OTP_ERROR, RESEND_OTP_LOADING, RESEND_OTP_SUCCESS, USER_LOGIN_ERROR, USER_LOGIN_LOADING, USER_LOGIN_SUCCESS, VERIFY_OTP_ERROR, VERIFY_OTP_LOADING, VERIFY_OTP_SUCCESS } from "./actionTypes";

const initialState = {
    login: {
        loading: false,
        data: null,
        error: null
    },
    verifyOtp: {
        loading: false,
        data: null,
        error: null
    },
    resendOtp: {
        loading: false,
        data: null,
        error: null
    },
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case USER_LOGIN_LOADING:
            return { ...state, login: { ...state.login, loading: true, data: null, error: null } };
        case USER_LOGIN_SUCCESS:
            return { ...state, login: { ...state.login, loading: false, data: action.payload, error: null } };
        case USER_LOGIN_ERROR:
            return { ...state, login: { ...state.login, loading: false, data: null, error: action.payload } };

        case VERIFY_OTP_LOADING:
            return { ...state, verifyOtp: { ...state.verifyOtp, loading: true, data: null, error: null } };
        case VERIFY_OTP_SUCCESS:
            return { ...state, verifyOtp: { ...state.verifyOtp, loading: false, data: action.payload, error: null } };
        case VERIFY_OTP_ERROR:
            return { ...state, verifyOtp: { ...state.verifyOtp, loading: false, data: null, error: action.payload } };

        case RESEND_OTP_LOADING:
            return { ...state, resendOtp: { ...state.resendOtp, loading: true, data: null, error: null } };
        case RESEND_OTP_SUCCESS:
            return { ...state, resendOtp: { ...state.resendOtp, loading: false, data: action.payload, error: null } };
        case RESEND_OTP_ERROR:
            return { ...state, resendOtp: { ...state.resendOtp, loading: false, data: null, error: action.payload } };

        default:
            return state;
    }
};

export default reducer;
