import { CHANGE_ORDER_STATUS_ERROR, CHANGE_ORDER_STATUS_LOADING, CHANGE_ORDER_STATUS_SUCCESS, CREATE_ORDER_ERROR, CREATE_ORDER_LOADING, CREATE_ORDER_SUCCESS, DELETE_ORDER_ERROR, DELETE_ORDER_LOADING, DELETE_ORDER_SUCCESS, GET_CATEGORIES_ERROR, GET_CATEGORIES_LOADING, GET_CATEGORIES_SUCCESS, GET_CUSTOMER_ERROR, GET_CUSTOMER_LOADING, GET_CUSTOMER_SUCCESS, GET_ITEMS_ERROR, GET_ITEMS_LOADING, GET_ITEMS_SUCCESS, GET_ORDERS_ERROR, GET_ORDERS_LOADING, GET_ORDERS_SUCCESS, GET_ORDER_DETAILS_ERROR, GET_ORDER_DETAILS_LOADING, GET_ORDER_DETAILS_SUCCESS, GET_ORDER_FORM_ERROR, GET_ORDER_FORM_LOADING, GET_ORDER_FORM_SUCCESS, GET_SALES_PERSON_ERROR, GET_SALES_PERSON_LOADING, GET_SALES_PERSON_SUCCESS, PRINT_ORDER_ERROR, PRINT_ORDER_LOADING, PRINT_ORDER_SUCCESS, UPDATE_ORDER_ERROR, UPDATE_ORDER_LOADING, UPDATE_ORDER_SUCCESS } from './actionTypes';

// get sales persons
const getSalesPersonLoading = () => {
  return {
    type: GET_SALES_PERSON_LOADING,
  };
};

const getSalesPersonSuccess = (data) => {
  return {
    type: GET_SALES_PERSON_SUCCESS,
    payload: data,
  };
};

const getSalesPersonError = (error) => {
  return {
    type: GET_SALES_PERSON_ERROR,
    payload: error,
  };
};

// get customers
const getCustomerLoading = () => {
  return {
    type: GET_CUSTOMER_LOADING,
  };
};

const getCustomerSuccess = (data) => {
  return {
    type: GET_CUSTOMER_SUCCESS,
    payload: data,
  };
};

const getCustomerError = (error) => {
  return {
    type: GET_CUSTOMER_ERROR,
    payload: error,
  };
};

// get categories
const getCategoriesLoading = () => {
  return {
    type: GET_CATEGORIES_LOADING,
  };
};

const getCategoriesSuccess = (data) => {
  return {
    type: GET_CATEGORIES_SUCCESS,
    payload: data,
  };
};

const getCategoriesError = (error) => {
  return {
    type: GET_CATEGORIES_ERROR,
    payload: error,
  };
};

// get items
const getItemsLoading = () => {
  return {
    type: GET_ITEMS_LOADING,
  };
};

const getItemsSuccess = (data) => {
  return {
    type: GET_ITEMS_SUCCESS,
    payload: data,
  };
};

const getItemsError = (error) => {
  return {
    type: GET_ITEMS_ERROR,
    payload: error,
  };
};

// get order form
const getOrderFormLoading = () => {
  return {
    type: GET_ORDER_FORM_LOADING,
  };
};

const getOrderFormSuccess = (data) => {
  return {
    type: GET_ORDER_FORM_SUCCESS,
    payload: data,
  };
};

const getOrderFormError = (error) => {
  return {
    type: GET_ORDER_FORM_ERROR,
    payload: error,
  };
};

// create order
const createOrderLoading = () => {
  return {
    type: CREATE_ORDER_LOADING,
  };
};

const createOrderSuccess = (data) => {
  return {
    type: CREATE_ORDER_SUCCESS,
    payload: data,
  };
};

const createOrderError = (error) => {
  return {
    type: CREATE_ORDER_ERROR,
    payload: error,
  };
};

// get orders
const getOrdersLoading = () => {
  return {
    type: GET_ORDERS_LOADING,
  };
};

const getOrdersSuccess = (data) => {
  return {
    type: GET_ORDERS_SUCCESS,
    payload: data,
  };
};

const getOrdersError = (error) => {
  return {
    type: GET_ORDERS_ERROR,
    payload: error,
  };
};

// get order details
const getOrderDetailsLoading = () => {
  return {
    type: GET_ORDER_DETAILS_LOADING,
  };
};

const getOrderDetailsSuccess = (data) => {
  return {
    type: GET_ORDER_DETAILS_SUCCESS,
    payload: data,
  };
};

const getOrderDetailsError = (error) => {
  return {
    type: GET_ORDER_DETAILS_ERROR,
    payload: error,
  };
};

// update order
const updateOrderLoading = () => {
  return {
    type: UPDATE_ORDER_LOADING,
  };
};

const updateOrderSuccess = (data) => {
  return {
    type: UPDATE_ORDER_SUCCESS,
    payload: data,
  };
};

const updateOrderError = (error) => {
  return {
    type: UPDATE_ORDER_ERROR,
    payload: error,
  };
};

// delete order
const deleteOrderLoading = () => {
  return {
    type: DELETE_ORDER_LOADING,
  };
};

const deleteOrderSuccess = (data) => {
  return {
    type: DELETE_ORDER_SUCCESS,
    payload: data,
  };
};

const deleteOrderError = (error) => {
  return {
    type: DELETE_ORDER_ERROR,
    payload: error,
  };
};

// change order status
const changeOrderStatusLoading = () => {
  return {
    type: CHANGE_ORDER_STATUS_LOADING,
  };
};

const changeOrderStatusSuccess = (data) => {
  return {
    type: CHANGE_ORDER_STATUS_SUCCESS,
    payload: data,
  };
};

const changeOrderStatusError = (error) => {
  return {
    type: CHANGE_ORDER_STATUS_ERROR,
    payload: error,
  };
};

// print order
const printOrderLoading = () => {
  return {
    type: PRINT_ORDER_LOADING,
  };
};

const printOrderSuccess = (data) => {
  return {
    type: PRINT_ORDER_SUCCESS,
    payload: data,
  };
};

const printOrderError = (error) => {
  return {
    type: PRINT_ORDER_ERROR,
    payload: error,
  };
};

export { 
    getSalesPersonLoading, 
    getSalesPersonSuccess, 
    getSalesPersonError,

    getCustomerLoading, 
    getCustomerSuccess, 
    getCustomerError,

    getCategoriesLoading, 
    getCategoriesSuccess, 
    getCategoriesError,

    getItemsLoading, 
    getItemsSuccess, 
    getItemsError,

    getOrderFormLoading, 
    getOrderFormSuccess, 
    getOrderFormError,

    createOrderLoading, 
    createOrderSuccess, 
    createOrderError,

    getOrdersLoading, 
    getOrdersSuccess, 
    getOrdersError,

    getOrderDetailsLoading, 
    getOrderDetailsSuccess, 
    getOrderDetailsError,

    updateOrderLoading, 
    updateOrderSuccess, 
    updateOrderError,
    
    deleteOrderLoading, 
    deleteOrderSuccess, 
    deleteOrderError,

    changeOrderStatusLoading, 
    changeOrderStatusSuccess, 
    changeOrderStatusError,

    printOrderLoading, 
    printOrderSuccess, 
    printOrderError,

};
