import { Wrapper } from "../../components";
import React, { useEffect, useState } from "react";

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { TextField } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Joi from "joi";

const useStyles = makeStyles(theme => ({
    avatar: {
        display: "inline-block",
        margin: 0,
        [theme.breakpoints.up("sm")]: {
            width: 128,
            height: 128
        },
        [theme.breakpoints.down("xs")]: {
            width: 64,
            height: 64
        },
        marginBottom: theme.spacing(1)
    },
    tabRoot: {
        textTransform: "initial",
        color: theme.palette.text.primary
    },
    postInput: {
        border: 0,
        width: "100%",
        [theme.breakpoints.up("sm")]: {
            padding: theme.spacing(1) * 3
        },
        [theme.breakpoints.down("xs")]: {
            padding: theme.spacing(1) * 2
        },
        fontSize: "13px",
        outline: 0,
        backgroundColor: theme.palette.background.paper
    }
}));

const Profile = () => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const profile = useSelector((state) => state?.admin?.adminUser);

    useEffect(() => {
        document.title = 'Profile';
        // dispatch(getAdminUserAction())
    }, []);

    useEffect(() => {
        if (profile?.data?.success) {
            const data = profile?.data?.success;
            setUserFormData({
                _id: data?._id,
                first_name: data?.first_name,
                last_name: data?.last_name,
                email: data?.email,
                password: null,
                mobile: data?.mobile.toString(),
                // profile_image: data?.user_profile ? data?.user_profile : '/static/images/avatar.png'
            })
        }
    }, [profile]);

    const [userFormData, setUserFormData] = useState(null);

    const userDataHandleChange = (e) => {
        setUserFormData({
            ...userFormData,
            [e.target.name]: e.target.value
        });
    }

    const handleMobileChange = (e) => {
        const newValue = e.target.value;
        if (newValue.length <= 10) {
            userDataHandleChange(e);
        } else {
            e.preventDefault();
        }
    };

    const update = () => {
        const validationResult = updateSchema.validate(userFormData, { abortEarly: false });
        if (validationResult.error) {
            const validationErrors = {};
            validationResult.error.details.forEach((err) => {
                validationErrors[err.path[0]] = err.message;
            });
            setErrors(validationErrors);
        } else {
            setErrors({});
            // dispatch(adminUpdateUserProfileAction(userFormData));
        }
    }

    const [errors, setErrors] = useState({});
    const passwordComplexity = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

    const updateSchema = Joi.object({
        _id: Joi.string().required().label('_id'),
        first_name: Joi.string().required().label('First Name'),
        last_name: Joi.string().required().label('Last Name'),
        email: Joi.string().email({ tlds: { allow: false } }).required().label('Email'),
        password: Joi.string().allow(null, '')
            .required()
            .regex(passwordComplexity)
            .messages({
                'string.pattern.base': 'Password must be contain at least one lowercase letter, one uppercase letter, one digit, and one special character from @$!%*?&',
            })
            .min(8)
            .label('Password'),
        mobile: Joi.string()
            .required()
            .pattern(/^\d{10}$/)
            .message('Mobile number must be a 10-digit number without any spaces or special characters')
            .label('Mobile'),
    });

    return (
        <Wrapper>
            <Grid>
                <div>
                    <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                        <div className="text-xs-center">
                            <Avatar
                                alt="Adelle Charles"
                                src={userFormData?.profile_image ? userFormData?.profile_image : '/static/images/avatar.png'}
                                className={classNames(classes.avatar)}
                            />
                        </div>
                        <Typography variant="h6" gutterBottom style={{ textAlign: 'center' }}>
                            {userFormData?.first_name} <b>{userFormData?.last_name}</b>
                        </Typography>
                    </div>
                </div>
                <Divider />
                <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '25px' }}>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }} style={{ maxWidth: '450px' }}>
                        <Grid item xs={12} sm={12} md={6} xl={6} className='mb-2 px-1'>
                            <TextField
                                className='w-100'
                                required
                                name='first_name'
                                label="First Name"
                                variant="outlined"
                                size="small"
                                value={userFormData ? userFormData?.first_name : ''}
                                error={errors?.first_name}
                                helperText={errors?.first_name}
                                onChange={(e) => userDataHandleChange(e)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} xl={6} className='mb-2 px-1'>
                            <TextField
                                className='w-100'
                                required
                                name='last_name'
                                label="Last Name"
                                variant="outlined"
                                size="small"
                                value={userFormData ? userFormData?.last_name : ''}
                                error={errors?.last_name}
                                helperText={errors?.last_name}
                                onChange={(e) => userDataHandleChange(e)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} xl={6} className='mb-2 px-1'>
                            <TextField
                                className='w-100'
                                required
                                name='email'
                                label="E-Mail"
                                variant="outlined"
                                size="small"
                                type='email'
                                value={userFormData ? userFormData?.email : ''}
                                error={errors?.email}
                                helperText={errors?.email}
                                onChange={(e) => userDataHandleChange(e)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} xl={6} className='mb-2 px-1'>
                            <TextField
                                className='w-100'
                                required
                                name='password'
                                label="Password"
                                variant="outlined"
                                size="small"
                                type='password'
                                value={userFormData ? userFormData?.password : ''}
                                error={errors?.password}
                                helperText={errors?.password}
                                onChange={(e) => userDataHandleChange(e)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} xl={12} className='mb-2 px-1'>
                            <TextField
                                className='w-100'
                                required
                                name='mobile'
                                label="Mobile"
                                variant="outlined"
                                size="small"
                                type='number'
                                value={userFormData ? userFormData?.mobile : ''}
                                error={errors?.mobile}
                                helperText={errors?.mobile}
                                onChange={(e) => handleMobileChange(e)}
                            />
                        </Grid>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <Button variant="contained" color="primary" onClick={update}>
                                Update Profile
                            </Button>
                        </div>
                    </Grid>
                </div>
            </Grid>
        </Wrapper>
    );
};

export default Profile;
