export const GET_SALES_PERSON_LOADING = 'GET_SALES_PERSON_LOADING';
export const GET_SALES_PERSON_SUCCESS = 'GET_SALES_PERSON_SUCCESS';
export const GET_SALES_PERSON_ERROR = 'GET_SALES_PERSON_ERROR';

export const GET_CUSTOMER_LOADING = 'GET_CUSTOMER_LOADING';
export const GET_CUSTOMER_SUCCESS = 'GET_CUSTOMER_SUCCESS';
export const GET_CUSTOMER_ERROR = 'GET_CUSTOMER_ERROR';

export const GET_CATEGORIES_LOADING = 'GET_CATEGORIES_LOADING';
export const GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS';
export const GET_CATEGORIES_ERROR = 'GET_CATEGORIES_ERROR';

export const GET_ITEMS_LOADING = 'GET_ITEMS_LOADING';
export const GET_ITEMS_SUCCESS = 'GET_ITEMS_SUCCESS';
export const GET_ITEMS_ERROR = 'GET_ITEMS_ERROR';

export const GET_ORDER_FORM_LOADING = 'GET_ORDER_FORM_LOADING';
export const GET_ORDER_FORM_SUCCESS = 'GET_ORDER_FORM_SUCCESS';
export const GET_ORDER_FORM_ERROR = 'GET_ORDER_FORM_ERROR';

export const CREATE_ORDER_LOADING = 'CREATE_ORDER_LOADING';
export const CREATE_ORDER_SUCCESS = 'CREATE_ORDER_SUCCESS';
export const CREATE_ORDER_ERROR = 'CREATE_ORDER_ERROR';

export const GET_ORDERS_LOADING = 'GET_ORDERS_LOADING';
export const GET_ORDERS_SUCCESS = 'GET_ORDERS_SUCCESS';
export const GET_ORDERS_ERROR = 'GET_ORDERS_ERROR';

export const GET_ORDER_DETAILS_LOADING = 'GET_ORDER_DETAILS_LOADING';
export const GET_ORDER_DETAILS_SUCCESS = 'GET_ORDER_DETAILS_SUCCESS';
export const GET_ORDER_DETAILS_ERROR = 'GET_ORDER_DETAILS_ERROR';

export const UPDATE_ORDER_LOADING = 'UPDATE_ORDER_LOADING';
export const UPDATE_ORDER_SUCCESS = 'UPDATE_ORDER_SUCCESS';
export const UPDATE_ORDER_ERROR = 'UPDATE_ORDER_ERROR';

export const DELETE_ORDER_LOADING = 'DELETE_ORDER_LOADING';
export const DELETE_ORDER_SUCCESS = 'DELETE_ORDER_SUCCESS';
export const DELETE_ORDER_ERROR = 'DELETE_ORDER_ERROR';

export const CHANGE_ORDER_STATUS_LOADING = 'CHANGE_ORDER_STATUS_LOADING';
export const CHANGE_ORDER_STATUS_SUCCESS = 'CHANGE_ORDER_STATUS_SUCCESS';
export const CHANGE_ORDER_STATUS_ERROR = 'CHANGE_ORDER_STATUS_ERROR';

export const PRINT_ORDER_LOADING = 'PRINT_ORDER_LOADING';
export const PRINT_ORDER_SUCCESS = 'PRINT_ORDER_SUCCESS';
export const PRINT_ORDER_ERROR = 'PRINT_ORDER_ERROR';
