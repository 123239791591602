import { changeOrderStatusError, changeOrderStatusLoading, changeOrderStatusSuccess, createOrderError, createOrderLoading, createOrderSuccess, deleteOrderError, deleteOrderLoading, deleteOrderSuccess, getCategoriesError, getCategoriesLoading, getCategoriesSuccess, getCustomerError, getCustomerLoading, getCustomerSuccess, getItemsError, getItemsLoading, getItemsSuccess, getOrderDetailsError, getOrderDetailsLoading, getOrderDetailsSuccess, getOrderFormError, getOrderFormLoading, getOrderFormSuccess, getOrdersError, getOrdersLoading, getOrdersSuccess, getSalesPersonError, getSalesPersonLoading, getSalesPersonSuccess, printOrderError, printOrderLoading, printOrderSuccess, updateOrderError, updateOrderLoading, updateOrderSuccess } from './actions';
import api from '../api';
import { toast } from 'react-toastify';

const getSalesPersonAction = (postData) => {
  return async (dispatch) => {
    try {
      // Start the loading state
      dispatch(getSalesPersonLoading());

      // Perform the asynchronous operation (e.g., API call)
      const response = await api.post('services/ord_services_v1.php', { ...postData, action: 'getSalesPerson' });
      const data = response.data;

      // Dispatch success action with the received data
      dispatch(getSalesPersonSuccess(data));
      if (data?.success) {
        // toast.success(data?.message);
      }
      if (data?.error) {
        toast.error(data?.error);
      }
    } catch (error) {
      // Dispatch error action if an error occurs
      dispatch(getSalesPersonError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

const getCustomerAction = (postData) => {
  return async (dispatch) => {
    try {
      // Start the loading state
      dispatch(getCustomerLoading());

      // Perform the asynchronous operation (e.g., API call)
      const response = await api.post('services/ord_services_v1.php', { ...postData, action: 'getCustomers' });
      const data = response.data;

      // Dispatch success action with the received data
      dispatch(getCustomerSuccess(data));
      if (data?.success) {
        // toast.success(data?.message);
      }
      if (data?.error) {
        toast.error(data?.error);
      }
    } catch (error) {
      // Dispatch error action if an error occurs
      dispatch(getCustomerError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

const getCategoriesAction = (postData) => {
  return async (dispatch) => {
    try {
      // Start the loading state
      dispatch(getCategoriesLoading());

      // Perform the asynchronous operation (e.g., API call)
      const response = await api.post('services/ord_services_v1.php', { ...postData, action: 'getCategories' });
      const data = response.data;

      // Dispatch success action with the received data
      dispatch(getCategoriesSuccess(data));
      if (data?.success) {
        // toast.success(data?.message);
      }
      if (data?.error) {
        toast.error(data?.error);
      }
    } catch (error) {
      // Dispatch error action if an error occurs
      dispatch(getCategoriesError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

const getItemsAction = (postData) => {
  return async (dispatch) => {
    try {
      // Start the loading state
      dispatch(getItemsLoading());

      // Perform the asynchronous operation (e.g., API call)
      const response = await api.post('services/ord_services_v1.php', { ...postData, action: 'getItems' });
      const data = response.data;

      // Dispatch success action with the received data
      dispatch(getItemsSuccess(data));
      if (data?.success) {
        // toast.success(data?.message);
      }
      if (data?.error) {
        // toast.error(data?.error);
      }
    } catch (error) {
      // Dispatch error action if an error occurs
      dispatch(getItemsError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

const getOrderFormAction = (postData) => {
  return async (dispatch) => {
    try {
      // Start the loading state
      dispatch(getOrderFormLoading());

      // Perform the asynchronous operation (e.g., API call)
      const response = await api.post('services/ord_services_v1.php', { ...postData, action: 'getOrderForm' });
      const data = response.data;

      // Dispatch success action with the received data
      dispatch(getOrderFormSuccess(data));
      if (data?.success) {
        // toast.success(data?.message);
      }
      if (data?.error) {
        // toast.error(data?.error);
      }
    } catch (error) {
      // Dispatch error action if an error occurs
      dispatch(getOrderFormError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

const createOrderAction = (postData) => {
  return async (dispatch) => {
    try {
      // Start the loading state
      dispatch(createOrderLoading());

      // Perform the asynchronous operation (e.g., API call)
      const response = await api.post('services/ord_services_v1.php', { ...postData, action: 'createOrder' });
      const data = response.data;

      // Dispatch success action with the received data
      dispatch(createOrderSuccess(data));
      if (data?.success) {
        toast.success(data?.message);
      }
      if (data?.error) {
        toast.error(data?.error);
      }
    } catch (error) {
      // Dispatch error action if an error occurs
      dispatch(createOrderError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

const getOrdersAction = (postData) => {
  return async (dispatch) => {
    try {
      // Start the loading state
      dispatch(getOrdersLoading());

      // Perform the asynchronous operation (e.g., API call)
      const response = await api.post('services/ord_services_v1.php', { ...postData, action: 'getOrders' });
      const data = response.data;

      // Dispatch success action with the received data
      dispatch(getOrdersSuccess(data));
      if (data?.success) {
        // toast.success(data?.message);
      }
      if (data?.error) {
        // toast.error(data?.error);
      }
    } catch (error) {
      // Dispatch error action if an error occurs
      dispatch(getOrdersError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

const getOrderDetailsAction = (postData) => {
  return async (dispatch) => {
    try {
      // Start the loading state
      dispatch(getOrderDetailsLoading());

      // Perform the asynchronous operation (e.g., API call)
      const response = await api.post('services/ord_services_v1.php', { ...postData, action: 'getOrderDetails' });
      const data = response.data;

      // Dispatch success action with the received data
      dispatch(getOrderDetailsSuccess(data));
      if (data?.success) {
        // toast.success(data?.message);
      }
      if (data?.error) {
        toast.error(data?.error);
      }
    } catch (error) {
      // Dispatch error action if an error occurs
      dispatch(getOrderDetailsError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

const updateOrderAction = (postData) => {
  return async (dispatch) => {
    try {
      // Start the loading state
      dispatch(updateOrderLoading());

      // Perform the asynchronous operation (e.g., API call)
      const response = await api.post('services/ord_services_v1.php', { ...postData, action: 'updateOrder' });
      const data = response.data;

      // Dispatch success action with the received data
      dispatch(updateOrderSuccess(data));
      if (data?.success) {
        toast.success(data?.message);
      }
      if (data?.error) {
        toast.error(data?.error);
      }
    } catch (error) {
      // Dispatch error action if an error occurs
      dispatch(updateOrderError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

const deleteOrderAction = (postData) => {
  return async (dispatch) => {
    try {
      // Start the loading state
      dispatch(deleteOrderLoading());

      // Perform the asynchronous operation (e.g., API call)
      const response = await api.post('services/ord_services_v1.php', { ...postData, action: 'deleteOrder' });
      const data = response.data;

      // Dispatch success action with the received data
      dispatch(deleteOrderSuccess(data));
      if (data?.success) {
        toast.success(data?.message);
      }
      if (data?.error) {
        toast.error(data?.error);
      }
    } catch (error) {
      // Dispatch error action if an error occurs
      dispatch(deleteOrderError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

const changeOrderStatusAction = (postData) => {
  return async (dispatch) => {
    try {
      // Start the loading state
      dispatch(changeOrderStatusLoading());

      // Perform the asynchronous operation (e.g., API call)
      const response = await api.post('services/ord_services_v1.php', { ...postData, action: 'changeOrderStatus' });
      const data = response.data;

      // Dispatch success action with the received data
      dispatch(changeOrderStatusSuccess(data));
      if (data?.success) {
        toast.success(data?.message);
      }
      if (data?.error) {
        toast.error(data?.error);
      }
    } catch (error) {
      // Dispatch error action if an error occurs
      dispatch(changeOrderStatusError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

const printOrderAction = (postData) => {
  return async (dispatch) => {
    try {
      // Start the loading state
      dispatch(printOrderLoading());

      // Perform the asynchronous operation (e.g., API call)
      const response = await api.post('services/ord_services_v1.php', { ...postData, action: 'printOrderForm' });
      const data = response.data;

      // Dispatch success action with the received data
      dispatch(printOrderSuccess(data));
      if (data?.success) {
        // toast.success(data?.message);
      }
      if (data?.error) {
        toast.error(data?.error);
      }
    } catch (error) {
      // Dispatch error action if an error occurs
      dispatch(printOrderError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

export {
  getSalesPersonAction,
  getCustomerAction,
  getCategoriesAction,
  getItemsAction,
  getOrderFormAction,
  createOrderAction,
  getOrdersAction,
  getOrderDetailsAction,
  updateOrderAction,
  deleteOrderAction,
  changeOrderStatusAction,
  printOrderAction
};
