import { RESEND_OTP_ERROR, RESEND_OTP_LOADING, RESEND_OTP_SUCCESS, USER_LOGIN_ERROR, USER_LOGIN_LOADING, USER_LOGIN_SUCCESS, VERIFY_OTP_ERROR, VERIFY_OTP_LOADING, VERIFY_OTP_SUCCESS } from './actionTypes';

// users login
const userLoginLoading = () => {
  return {
    type: USER_LOGIN_LOADING,
  };
};

const userLoginSuccess = (data) => {
  return {
    type: USER_LOGIN_SUCCESS,
    payload: data,
  };
};

const userLoginError = (error) => {
  return {
    type: USER_LOGIN_ERROR,
    payload: error,
  };
};

// verify otp
const verifyOtpLoading = () => {
  return {
    type: VERIFY_OTP_LOADING,
  };
};

const verifyOtpSuccess = (data) => {
  return {
    type: VERIFY_OTP_SUCCESS,
    payload: data,
  };
};

const verifyOtpError = (error) => {
  return {
    type: VERIFY_OTP_ERROR,
    payload: error,
  };
};

// resend otp
const resendOtpLoading = () => {
  return {
    type: RESEND_OTP_LOADING,
  };
};

const resendOtpSuccess = (data) => {
  return {
    type: RESEND_OTP_SUCCESS,
    payload: data,
  };
};

const resendOtpError = (error) => {
  return {
    type: RESEND_OTP_ERROR,
    payload: error,
  };
};

export { 
    userLoginLoading, 
    userLoginSuccess, 
    userLoginError,

    verifyOtpLoading, 
    verifyOtpSuccess, 
    verifyOtpError,

    resendOtpLoading, 
    resendOtpSuccess, 
    resendOtpError,
};
