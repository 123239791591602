import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Typography from '@material-ui/core/Typography';
import { Button, Card, CircularProgress, Collapse, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fab, Menu, MenuItem, TextField } from "@material-ui/core";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import AddIcon from '@material-ui/icons/Add';
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { changeOrderStatusAction, deleteOrderAction, getOrdersAction, printOrderAction } from "../../store/new-order/asyncActions";
import { useSelector } from "react-redux";
import axios from "axios";
import InfiniteScroll from "react-infinite-scroll-component";
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import VisibilityIcon from '@material-ui/icons/Visibility';

const Orders = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const userData = localStorage.getItem('user');

  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [printLoading, setPrintLoading] = useState(null);
  const [page, setPage] = useState(0);
  const [orders, setOrders] = useState([]);
  const [search, setSearch] = useState('');

  const [deleteModal, setDeleteModal] = useState(false);
  const [ID, setID] = useState(null);

  const ordersData = useSelector((state) => state?.newOrder?.getOrders);
  const deleteOrdersData = useSelector((state) => state?.newOrder?.deleteOrder);
  const changeStatusData = useSelector((state) => state?.newOrder?.changeStatus);
  const printOrderData = useSelector((state) => state?.newOrder?.printOrder);

  useEffect(() => {
    document.title = 'Orders - Natural Veneers';
  }, []);

  useEffect(() => {
    if (userData && userData.trim() !== '') {
      setUser(JSON.parse(userData));
    }
  }, [userData]);

  const fetchOrders = (page) => {
    dispatch(getOrdersAction({
      auth_token: user?.auth_token,
      user_type: user?.user_type,
      user_id: user?.user_id,
      start: page,
      search: search
    }));
  }

  useEffect(() => {
    if (user) {
      setHasMore(true);
      fetchOrders(0);
    }
  }, [user, search]);

  useEffect(() => {
    if (ordersData?.data?.success) {
      if (page === 0) {
        setOrders(ordersData?.data?.data);
      } else {
        setOrders(prevData => [...prevData, ...ordersData?.data?.data]);
      }
      // setPage(prevPage => prevPage + 1);
    } else if (ordersData?.data?.error) {
      // setOrders([]);
      setHasMore(false);
    }
  }, [ordersData]);

  useEffect(() => {
    if (deleteOrdersData?.data?.success || changeStatusData?.data?.success) {
      dispatch(getOrdersAction({
        auth_token: user?.auth_token,
        user_type: user?.user_type,
        user_id: user?.user_id,
        start: page,
        search: search
      }));
      deleteOrdersData.data = null;
      changeStatusData.data = null;
    }
  }, [deleteOrdersData, changeStatusData]);

  useEffect(() => {
    if (printOrderData?.data?.success) {
      // downloadPdf(printOrderData?.data?.data?.pdf);
      window.open(printOrderData?.data?.data?.pdf, '_blank');
      setPrintLoading({
        ...printLoading,
        [fileNameFromURL(printOrderData?.data?.data?.pdf)]: false
      });
      printOrderData.data = null;
    }
  }, [printOrderData]);

  const fileNameFromURL = (url) => {
    const parts = url.split('/');
    const fileName = parts.pop();
    return fileName;
  }

  const downloadPdf = async (fileUrl) => {
    const proxyUrl = 'https://api.allorigins.win/raw?url=';
    try {
      const response = await axios.get(proxyUrl + fileUrl, {
        responseType: 'blob', // Set the response type to blob
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileNameFromURL(fileUrl));
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);

      setPrintLoading({
        ...printLoading,
        [fileNameFromURL(fileUrl)]: false
      });

    } catch (error) {
      console.error('Error downloading the file:', error);
    }
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(null);

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(null);
  };

  const editOrder = (id) => {
    history.push(`/new-order?id=${id}`);
  }

  const deleteOrder = (id) => {
    dispatch(deleteOrderAction({
      auth_token: user?.auth_token,
      user_type: user?.user_type,
      user_id: user?.user_id,
      order_id: ID
    }));
    setDeleteModal(false);
  }

  const changeStatus = (id, status) => {
    dispatch(changeOrderStatusAction({
      auth_token: user?.auth_token,
      user_type: user?.user_type,
      user_id: user?.user_id,
      order_id: id,
      status: status
    }));
    setAnchorEl(null);
    setOpen(null);
  }

  const printOrder = (id, orderNum) => {
    setPrintLoading({
      ...printLoading,
      [orderNum + '.pdf']: true
    });
    dispatch(printOrderAction({
      auth_token: user?.auth_token,
      user_type: user?.user_type,
      user_id: user?.user_id,
      order_id: id,
    }));
  }

  return (
    <div className="px-1 pt-1">
      <div className="title_With_Back mb-1">
        <Typography variant='h6' color="textSecondary">
          ORDERS
        </Typography>
        <Button variant="text" color="primary" onClick={() => {
          history.goBack();
        }}>
          <ArrowBackIosIcon />Back
        </Button>
      </div>

      <div>
        <TextField
          name="search_qry"
          variant="outlined"
          size="small"
          placeholder="Search by dealer name"
          fullWidth
          onChange={(e) => {
            setPage(0);
            setSearch(e?.target?.value);
          }}
        />
      </div>

      <div className="Order_list">

        <InfiniteScroll
          dataLength={orders.length || 0}
          next={() => {
            fetchOrders(page + 1);
            setPage(prevPage => prevPage + 1);
          }}
          hasMore={hasMore} // Set this to false if there are no more pages to load
          loader={
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
              <CircularProgress style={{ height: '35px', width: '35px' }} />
            </div>
          }
          scrollThreshold={0.95} // Load more data when the user scrolls to 90% of the page
        >
          {orders && orders.map((item, index) => {
            return (
              <Card className="py-1 my-2" key={index}>
                <div className="px-1">
                  <div className="Order_no">
                    <Typography variant='subtitle2' color="inherit" onClick={() => history.push(`/order-details?id=${item?.id}`)}>
                      ORDER NO : {item?.order_number}
                    </Typography>
                    <div className="OrderActions">
                      <div className={`Order_status ${item?.status === 'Pending' ? 'pending' : 'approved'}`} onClick={(e) => {
                        setAnchorEl(e.currentTarget);
                        setOpen(index);
                      }}>
                        {item?.status}
                      </div>
                      {item?.status === 'Pending' &&
                        <Menu
                          id={`StatsOpt${index}`}
                          className="Order_status_dd"
                          aria-labelledby="demo-positioned-button"
                          anchorEl={anchorEl}
                          open={open === index}
                          onClose={handleClose}
                          anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                          }}
                        >
                          <MenuItem>Pending</MenuItem>
                          <MenuItem onClick={() => changeStatus(item?.id, 'Approved')}>Approved</MenuItem>
                        </Menu>
                      }
                      <VisibilityIcon color="action" style={{ marginLeft: '10px' }} onClick={() => history.push(`/order-details?id=${item?.id}`)} />
                    </div>
                  </div>
                  <div>
                    <Typography variant='subtitle2' color="textSecondary">
                      {item?.customer_company}
                    </Typography>
                    <div>
                      <Typography variant='subtitle2' color="textSecondary">
                        Items : {item?.number_of_items}
                      </Typography>
                    </div>
                    <div className="person_name_div mt-1">
                      <Typography variant='subtitle2' color="textSecondary">
                        Sales Person Name :
                      </Typography>
                      <div className="person_name">{item?.sales_person_name}</div>
                    </div>
                    <div>
                      <div className="person_name_div">
                        <Typography variant='subtitle2' color="textSecondary">
                          Created By :
                        </Typography>
                        <div className="person_name">{`${item?.created_by} | ${item?.created_at}`}</div>
                      </div>
                    </div>
                    <div className="d_flex mt-1 order_action_btn">
                      {item?.status === 'Pending' &&
                        <Button variant="contained" color="default" fullWidth onClick={() => editOrder(item?.id)}>
                          EDIT
                        </Button>
                      }
                      <Button variant="contained" color="default" fullWidth onClick={() => {
                        setID(item?.id);
                        setDeleteModal(true);
                      }}>
                        DELETE
                      </Button>
                      <Button
                        variant="contained"
                        color="default"
                        fullWidth
                        disabled={printLoading?.[item?.order_number + '.pdf']}
                        onClick={() => printOrder(item?.id, item?.order_number)}
                      >
                        {printLoading?.[item?.order_number + '.pdf'] ? <CircularProgress style={{ height: '15px', width: '15px' }} /> : 'PRINT'}
                      </Button>
                    </div>
                  </div>
                </div>
              </Card>
            )
          })}
        </InfiniteScroll>

      </div>

      <Fab color="primary" aria-label="add" className="add_oreder_float_btn"
        onClick={() => {
          history.push('/new-order');
        }}
      >
        <AddIcon />
      </Fab>

      {/* delete confirm dialog */}
      <Dialog
        open={deleteModal}
        TransitionComponent={Collapse}
        // keepMounted
        onClose={() => setDeleteModal(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          Confirm Delete
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure you want to delete this record?
            This action is irreversible and will permanently remove the data.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => setDeleteModal(false)}>
            Cancel
          </Button>
          <Button color="primary" style={{ color: '#f44336' }} onClick={() => deleteOrder()}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

    </div>
  );
};

export default Orders;
